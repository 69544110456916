import React, { Suspense, lazy } from 'react'
import './assets/css/loader.css'
import './assets/css/style.css'
import './assets/css/anim.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
const Home = lazy(() => import('./page/Home'))
const About = lazy(() => import('./page/About'))
const Dimensions = lazy(() => import('./page/Dimensions'))
const Contact = lazy(() => import('./page/Contact'))
// const Event = lazy(() => import('./page/Event'))
// const EventDetail = lazy(() => import('./page/EventDetail'))
// const Membership = lazy(() => import('./page/Membership'))
const Program = lazy(() => import('./page/Program'))
// const ProgramDetail = lazy(() => import('./page/ProgramDetail'))
// const MembershipDetail = lazy(() => import('./page/MembershipDetail'))
const Space = lazy(() => import('./page/Space'))
const SpaceDetail = lazy(() => import('./page/SpaceDetail'))
const Blog = lazy(() => import('./page/Blog'))
const BlogDetail = lazy(() => import('./page/BlogDetail'))
// const Media = lazy(() => import('./page/Media'))
const Food = lazy(() => import('./page/Food'))
const Faq = lazy(() => import('./page/Faq'))
const DinningExperience = lazy(() => import('./page/DiningExperience'))
const PageContent = lazy(() => import('./page/PageContent'))

function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
      <Suspense fallback={<div>{/* Loading... */}</div>}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/dimensions' element={<Dimensions />} />
          <Route path='/contact' element={<Contact />} />
          {/* <Route path='/event' element={<Event />} /> */}
          {/* <Route path='/event/:id' element={<EventDetail />} /> */}

          {/* <Route path='/membership' element={<Membership />} /> */}
          {/* <Route path='/membership/:id' element={<MembershipDetail />} /> */}

          <Route path='/program' element={<Program />} />
          {/* <Route path='/program/:id' element={<ProgramDetail />} /> */}

          <Route path='/space' element={<Space />} />
          <Route path='/space/:id' element={<SpaceDetail />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:category' element={<Blog />} />
          <Route path='/blog/:category/:subcategory' element={<Blog />} />
          <Route
            path='/blog/:category/:subcategory/:blog'
            element={<BlogDetail />}
          />
          {/* <Route path='/media' element={<Media />} /> */}
          <Route path='/food-philosophy' element={<Food />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/dining-experiences' element={<DinningExperience />} />
          <Route path='*' element={<PageContent />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
