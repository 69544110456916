export async function callService(postArr) {
  let defaultParam = {
    url: '',
    data: {},
    methodType: 'POST',
    header: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }

  postArr = { ...defaultParam, ...postArr }
  var apiBoj: any = {}
  apiBoj = {
    method: postArr.methodType,
    headers: postArr.header,
  }
  if (Object.keys(postArr.data).length > 0) {
    apiBoj.body = postArr.data
  }
  return await fetch(process.env.REACT_APP_API_URL + postArr.url, apiBoj)
    .then((responce) => {
      return responce.json()
    })
    .then((responseJson) => {
      return responseJson
    })
    .catch((err) => {
      console.log('error', err)
    })
}
