import React, { useState, useEffect } from 'react';
import PageBannerContext from './PageBannerContext';
import { callService } from '../service/service';

const PageBannerProvider = ({ children }) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    let pathName = window.location.pathname;
    const path = pathName.substring(pathName.lastIndexOf('/') + 1);
    const fetchData = async () => {
        var postArr = {
            url: `/common/getpagebanners?pageSeoUrl=${path}`,
            methodType: 'GET',
        }
        let res= await callService(postArr)
        setData(res?.data);
    };

    fetchData();
  }, []);

  return (
    <PageBannerContext.Provider value={{ data}}>
      {children}
    </PageBannerContext.Provider>
  );
};

export default PageBannerProvider;
